<script>
import utilsMixin from "@mixins/utilsMixin";

export default {
  name: "AkActualite",
  mixins: [utilsMixin],
  props: {
    actualite: {
      type: Object,
      required: true
    }
  },
}
</script>

<template>
  <div class="card card-statistics">
    <div class="card-header">
      <div class="card-heading">
        <div class="row align-items-center">
          <div class="col-lg-6 col-8">
            <h5 class="card-title">{{ actualite.title }}</h5>
          </div>
          <div class="col-lg-6 col-4 text-right">
            Publié le {{ displayDate(actualite.dateStart) }} par {{ actualite.creatorName }}
          </div>
        </div>
      </div>
    </div>
    <div class="tinymce-result wysiwyg card-body vld-parent">
      <div v-html="actualite.content"></div>
    </div>
  </div>
</template>

<style scoped>
</style>