import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/actualites", {params: removeEmptyParams(filter)}).then(res => {
                res.data.forEach(e => {this.formatRes(e);})
                return res.data;
            }
        );
    },
    findAllDashboard() {
        return Api().get("/actualites/dashboard").then(res => {
                res.data.forEach(e => {this.formatRes(e);})
                return res.data;
            }
        );
    },
    findById(id) {
        return Api().get("/actualite/" + id).then(res => this.formatRes(res.data));
    },
    create(actualite) {
        return Api().post("/actualite", actualite).then(res => this.formatRes(res.data));
    },
    update(actualite) {
        return Api().put("/actualite/" + actualite.id, actualite).then(res => this.formatRes(res.data));
    },
    delete(actualite) {
        return Api().delete("/actualite/" + actualite.id).then(() => actualite);
    },
    formatRes(e) {
        if (e.dateStart) e.dateStart = new Date(e.dateStart);
        if (e.dateEnd) e.dateEnd = new Date(e.dateEnd);
        if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
        if (e.dateUpdated) e.dateUpdated = new Date(e.dateUpdated);
        return e;
    }
}