<script>

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDialog from "@components/general/AkDialog";
import AkActualite from "@components/general/AkActualite.vue";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";
import utilsMixin from "@mixins/utilsMixin";

/* SERVICES */
import actualiteService from "@services/actualiteService";
import archiveService from "@services/archiveService";

export default {
  components: {AkActualite, AkFormList, AkDialog},
  mixins: [randomRef, roleMixin, utilsMixin],
  metaInfo() {
    return {
      title: "home",
    }
  },
  data() {
    return {
      actualites: [],
      archiveInProgress: false,
      displayAllActualites: false,
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p1 = actualiteService.findAllDashboard();
    p1.then(data => this.actualites = data);

    Promise.all([p1]).then(() => {
      this.getRef().hideLoader();
    });
  },
  methods: {
    confirmArchive() {
      this.$refs.dialogArchive.show();
    },
    archive() {
      this.archiveInProgress = true;
      archiveService.archiveAll().then(() => {
        this.getRef().success(this.$t('archived'));
        this.archiveInProgress = false;
      })
          .catch((error) => {
            this.archiveInProgress = false;
          });
    },
  },
  computed: {
    actualitesDisplayable() {
      if (this.displayAllActualites) return this.actualites;
      return this.actualites.slice(0, 5);
    },
    allDisplayable() {
      return this.actualites.length <= 5;
    },
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormList
      :ref="ref"
      title="Bienvenue!"
      :displayGoBack=false>
    <template v-slot:action>
      <button v-if="!roleLoading && permissionList.includes('ADMIN_MANAGE')" @click=confirmArchive()
              class="btn btn-danger" :disabled="archiveInProgress" style="margin-left: 10px">
        <i class="pi pi-spin pi-spinner mr-1" v-if="archiveInProgress"></i>{{ $t('archive') }}
      </button>
    </template>
    <template v-slot:list>
      <div>
        <div class="row align-items-center mb-2 mb-sm-3">
          <div class="col-xxl-5 col-xl-5 mr-auto">
            <p>Vous êtes sur l'espace collaboratif VH de la DiRIF</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6" v-for="actualite of this.actualitesDisplayable" :key="actualite.title">
          <AkActualite :actualite="actualite"/>
        </div>
      </div>

      <div class="row mb-2" v-if="!this.displayAllActualites && !this.allDisplayable">
        <div class="col-md-3 mb-2 col-6">
          <div class="d-block d-sm-flex flex-nowrap align-items-center">
            <button @click="this.displayAllActualites = true" class="btn btn-inverse-primary">
              Voir plus d'actualités
            </button>
          </div>
        </div>
      </div>

    </template>
    <template v-slot:extra>
      <AkDialog
          :auto-hide-on-validate="true"
          :cancel-label="$t('no')"
          :title="$t('archive_dialog')"
          :validate-label="$t('yes')"
          @validate=archive
          ref="dialogArchive"
          width="450px">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('confirm_archive') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>